import React from "react";
import styles from "./Portfolio.module.css";

const Portfolio: React.FC = () => {
    return (
        <div className={styles.portfolioContainer}>

            <header className={styles.portfolioHeader}>
                <h1>Артём Демчук</h1>
                <p>JavaScript-разработчик | React | Node.js | REST API</p>
            </header>

            <section className={styles.aboutMe}>
                <h2>Обо мне</h2>
                <p>
                    Здравствуйте! Меня зовут Артём Демчук, я занимаюсь разработкой
                    современных веб-приложений. Специализируюсь на JavaScript, включая
                    такие технологии, как React, Node.js, и Docker. Я всегда стремлюсь к
                    созданию производительных и масштабируемых решений.
                </p>
            </section>

            <section className={styles.projects}>
                <h2>Мои проекты</h2>
                <div className={styles.projectCard}>
                    <h3>SPA "Сервис проката велосипедов"</h3>
                    <p>
                        Разработка одностраничного приложения для сервиса проката
                        велосипедов с использованием ReactJS, Redux и REST API.
                    </p>
                    <ul>
                        <li>Адаптивная вёрстка для всех устройств.</li>
                        <li>Маршрутизация на клиенте с React Router.</li>
                        <li>Интеграция с внешними API.</li>
                    </ul>
                    <div className={styles.projectLinks}>
                        <a
                            href="https://github.com/darterss/bike"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Код проекта
                        </a>
                    </div>
                </div>

                <div className={styles.projectCard}>
                    <h3>Telegram-бот для ChatGPT</h3>
                    <p>
                        Telegram-бот для общения с ChatGPT и приёма платежей через ЮKassa.
                        Реализован на Node.js и размещён на VPS.
                    </p>
                    <ul>
                        <li>Интеграция с OpenAI API.</li>
                        <li>Система оплаты через ЮKassa.</li>
                        <li>Хранение данных в PostgreSQL.</li>
                    </ul>
                    <div className={styles.projectLinks}>
                        <a
                            href="https://darters.ru"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Открыть сайт
                        </a>
                    </div>
                    <div className={styles.projectLinks}>
                        <a
                            href="https://t.me/GptDartersBot"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Открыть Telegram Bot
                        </a>
                    </div>
                </div>
            </section>

            <section className={styles.skills}>
                <h2>Навыки</h2>
                <ul className={styles.skillsList}>
                    <li>ReactJS / Redux</li>
                    <li>Node.js / Express</li>
                    <li>PostgreSQL</li>
                    <li>Docker / VPS / Nginx</li>
                    <li>Git / GitHub</li>
                    <li>Адаптивная вёрстка / Styled Components</li>
                </ul>
            </section>

            <section className={styles.contact}>
                <h2>Контакты</h2>
                <p>Email: darters@mail.ru</p>
                {/*<p>
                    GitHub: <a href="https://github.com/darterss">github.com/darterss</a>
                </p>*/}
                <p>
                    Telegram: <a href="https://t.me/darters26">@darters26</a>
                </p>
            </section>

            <footer className={styles.portfolioFooter}>
                <p>© 2025 Артём Демчук</p>
            </footer>
        </div>
    );
};

export default Portfolio;
