import React, { useState, useEffect, useRef } from 'react';
import './Chat.css';

const Chat: React.FC<{ token: string }> = ({ token }) => {
    const [messages, setMessages] = useState<{ role: string; content: string }[]>([]);
    const [input, setInput] = useState('');
    const [socket, setSocket] = useState<WebSocket | null>(null);
    const messageQueue = useRef<string[]>([]); // Буфер для сообщений
    const telegramId = sessionStorage.getItem('telegramId');
    const reconnectAttempts = useRef(0);
    const maxReconnectAttempts = 5;
    const [balance, setBalance] = useState(0);
    const [model, setModel] = useState(null);
    const [historyLimit, setHistoryLimit] = useState(0);
    const [isLowBalance, setIsLowBalance] = useState(false);

    const handleWebSocketData = (data: any) => {
        data.type === 'error_balance' ? setIsLowBalance(true) : setIsLowBalance(false);
        data.type === 'message' ? setMessages(data.message) : setMessages(data.user.history);
        setBalance(data.user.tokens_balance);
        setModel(data.user.model);
        setHistoryLimit(data.user.history_limit);
    };

    const connectWebSocket = () => {
        const ws = new WebSocket('wss://darters.ru/chat');

        ws.onopen = () => {
            console.log('WebSocket открыт');
            reconnectAttempts.current = 0; // Сброс попыток
            ws.send(JSON.stringify({ type: 'auth', token, telegramId }));

            // Отправка всех сообщений из буфера
            while (messageQueue.current.length > 0) {
                const message = messageQueue.current.shift();
                if (message) {
                    ws.send(
                        JSON.stringify({
                            type: 'message',
                            message,
                            telegramId
                        })
                    );
                }
            }
        };

        ws.onmessage = (event: MessageEvent<any>) => {
            const data = JSON.parse(event.data);
            handleWebSocketData(data); // Вызов вспомогательной функции
        };

        ws.onclose = () => {
            console.log('WebSocket закрыт');
            if (reconnectAttempts.current < maxReconnectAttempts) {
                setTimeout(() => {
                    reconnectAttempts.current += 1;
                    console.log(`Попытка переподключения #${reconnectAttempts.current}`);
                    connectWebSocket();
                }, 2000); // Интервал в 2 секунды
            } else {
                console.log('Превышено количество попыток переподключения');
            }
        };

        ws.onerror = (err) => {
            console.error('Ошибка WebSocket:', err);
            ws.close(); // Закрываем соединение, чтобы запустить onclose
        };

        setSocket(ws);
    };

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [token]);

    const sendMessage = () => {
        if (input.trim()) {
            const newMessage = input.trim();

            // Обновление локальных сообщений
            setMessages((prev) => Array.isArray(prev)
                ? [...prev, { role: 'user', content: newMessage }]
                : [{ role: 'user', content: newMessage }]
            );


            // Попытка отправить сообщение
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(
                    JSON.stringify({
                        type: 'message',
                        message: newMessage,
                        telegramId
                    })
                );
            } else {
                // Добавление в буфер, если WebSocket недоступен
                messageQueue.current.push(newMessage);
                console.log('Соединение закрыто. Сообщение добавлено в очередь.');
            }

            setInput(''); // Очистка поля ввода
        }
    };

    return (
        <div className='chat'>
            <div className='user-info-container'>
                <span className='user-info'>Баланс: {balance} токенов</span>
                <span className='user-info'>Модель: {model}</span>
                <span className='user-info'>Глубина контекста: {historyLimit}</span>
            </div>
            <div className='messages'>
                {Array.isArray(messages) && !isLowBalance ? messages.map((msg, idx) => (
                    <div key={idx} className={`message message-${msg.role}`}>
                        {msg.content}
                    </div>
                )) : isLowBalance ? <div className={'low-balance'}>Пожалуйста, пополните баланс</div> : ''}
            </div>
            <div className='input'>
                <input
                    type='text'
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder='Напишите сообщение...'
                    autoFocus
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            sendMessage();
                        }
                    }}
                />
                <button onClick={sendMessage}>Отправить</button>
            </div>
        </div>

    );
};

export default Chat;
